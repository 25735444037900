import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/common/layout";
import GovernmentNavbar from "../components/government/government-navbar";
import OrganizationList from "../components/government/organization-list";

export const query = graphql`
  query VillageOrganizationPageQuery {
    organizations: allSanityVillageOrganization(
      filter: { title: { ne: "null" }, slug: { current: { ne: "null" } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          id
          title
          leader
          slug {
            current
          }
        }
      }
    }
  }
`;

const VillageOrganizationPage = (props) => {
  const { data } = props;

  const nodes = (data || {}).organizations
    ? mapEdgesToNodes(data.organizations)
    : [];

  return (
    <Layout>
      <GovernmentNavbar />
      {nodes && <OrganizationList nodes={nodes} />}
    </Layout>
  );
};

export default VillageOrganizationPage;
